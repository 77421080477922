import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '@components/Layout';
import CookiePolicyPage from '../scenes/CookiePolicyPage/index';

const Page = ({ data }) => {
    const cookiePolicyPageData = data.allPrismicCookiePolicy.edges[0];
    if (!cookiePolicyPageData) return null;

    const cookiePolicyPageContent = cookiePolicyPageData.node;
    const {
        uid,
        id,
        type,
        alternate_languages,
        lang,
        data: pageData,
    } = cookiePolicyPageContent;
    const activeDocMeta = { id, uid, lang, type, alternate_languages };
    const {
        metatitle,
        metadescription,
        canonical,
        pagetitle: pageTitle,
    } = pageData;

    // console.log(data, "data into cookie page")
    // console.log(pageData, "pageData")

    return (
        <Layout
            activeDocMeta={activeDocMeta}
            metatitle={metatitle}
            metadescription={metadescription}
            canonical={canonical}
        >
            <CookiePolicyPage pageTitle={pageTitle} canonical={canonical} />
        </Layout>
    );
};

Page.propTypes = {
    data: PropTypes.object.isRequired,
};

export const query = graphql`
  query($uid: String, $lang: String) {
    allPrismicCookiePolicy(
      filter: { uid: { eq: $uid }, lang: { eq: $lang } }
    ) {
      edges {
        node {
          uid
          type
          lang
          id
          alternate_languages {
            id
            lang
            type
            uid
          }
          data {
            pagetitle {
              text
            }
            metatitle {
              text
            }
            metadescription {
              text
            }
            canonical {
              text
            }
          }
        }
      }
    }
  }
`;

export default Page;
